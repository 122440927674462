
    <template>
      <section class="content element-doc">
        <h2>PageHeader 页头</h2>
<p>如果页面的路径比较简单，推荐使用页头组件而非面包屑组件。</p>
<h3>基础</h3>
<demo-block>
        
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-page-header @back=&quot;goBack&quot; content=&quot;详情页面&quot;&gt; &lt;/el-page-header&gt;
&lt;/template&gt;
&lt;script&gt;
  export default {
    setup() {
      function goBack() {
        console.log('go back')
      }

      return { goBack }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>title</td>
<td>标题</td>
<td>string</td>
<td>—</td>
<td>返回</td>
</tr>
<tr>
<td>content</td>
<td>内容</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
</tbody>
</table>
<h3>Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>back</td>
<td>点击左侧区域触发</td>
<td>—</td>
</tr>
</tbody>
</table>
<h3>Slots</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>title</td>
<td>标题内容</td>
</tr>
<tr>
<td>content</td>
<td>内容</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_page_header = _resolveComponent("el-page-header")

  return (_openBlock(), _createBlock(_component_el_page_header, {
    onBack: _ctx.goBack,
    content: "详情页面"
  }, null, 8, ["onBack"]))
}
  
    const democomponentExport = {
    setup() {
      function goBack() {
        console.log('go back')
      }

      return { goBack }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  